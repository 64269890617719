import React, { useState, useEffect, useRef } from "react";
import '../css/eventos.css';
import style from '../css/eventos.css';
import swal from 'sweetalert';
import Modal from "react-modal"
import { event } from "react-ga";
//import logoPalePrincipal from '../../../../images/Logo_720.png';
import BarcodeScanner from "../../../componentes/BarcodeScanner";
import logo1 from "../images/bodal1.png";
import logo2 from "../images/bodal2.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Popup from "reactjs-popup";
import { IoPersonSharp, IoPhonePortrait, IoMailSharp, IoTicketSharp, IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";




const BuscarEvento = () => {
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fecha, setFecha] = useState('');
    const { codigo } = useParams();

    //=====================================================================================
    const [buscar, setBuscar] = useState("");
    const [codigoUsuarioSeleccionado, setCodigoUsuarioSeleccionado] = useState("");

    const [dataTable, setDataTable] = useState([]);

    const [horaAsistido, setHoraAsistido] = useState("");
    const [nombreAsistente, setNombreAsistente] = useState("");
    const [numeroAsistente, setNumeroAsistente] = useState("");
    const [email, setEmail] = useState("");
    const [pax, setPax] = useState("");
    const [observacion, setOservacion] = useState("");

    const [openModalConfirmar, setOpenModalConfirmar] = useState(false);
    const [openModalRegistro, setOpenModalRegistro] = useState(false);
    const [openModalSinData, setOpenModalSinData] = useState(false)
    const [openModalCodUsado, setOpenModalCodUsado] = useState(false)
    const [openModalUnicoInvitado, setOpenModalUnicoInvitado] = useState(false)
    //=====================================================================================


    const TraerEvento = () => {
        let jsonData2 = {

            Token: "484848",
            Operacion: "1",
            Conexion:
            {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_EVENTOS_TXPK"
            },
            Parametros:
                [
                    codigo
                ]

        };

        console.log({ jsonData2 });

        try {
            fetch(`https://apis.paleconsultores.com/v2/Pale/`, {
                method: "POST",
                body: JSON.stringify(jsonData2),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    setNombre(data.Resultado[0][0].Des_Evento)
                    setFecha(data.Resultado[0][0].Fecha_Inicio)
                    setDireccion(data.Resultado[0][0].LugarEvento)
                });

        } catch (error) {
            alert('Error con el servicio, por favor actualice la página');
        }
    }

    const handleScanSuccess = (decodedText) => {
        setBuscar(decodedText);
    };

    const handleScanError = (error) => {
        console.error("Error durante el escaneo:", error);
    };

    const limpiarCampos = () => {
        setHoraAsistido("");
        setNombreAsistente("");
        setNumeroAsistente("");
        setEmail("");
        setPax("");
        setOservacion("");
        setBuscar("");
    };

    const buscarData = (forzarBuscarTodo = false) => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_TXEventoBusqueda",
            },
            Parametros: [codigo, forzarBuscarTodo ? "" : buscar],
        };
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {

                    // if (data.Resultado[0]?.length === 1 && data.Resultado[0][0].Nom_Invitado !== '' && !data.Resultado[0][0].Flag_Asitio) {
                    //     // console.log(data.Resultado[0])
                    //     setCodigoUsuarioSeleccionado(data.Resultado[0][0].Cod_Invitado)
                    //     setNombreAsistente(data.Resultado[0][0].Nom_Invitado)
                    //     setPax(data.Resultado[0][0].Pax)
                    //     setNumeroAsistente(data.Resultado[0][0].Celular)
                    //     setEmail(data.Resultado[0][0].Email)
                    //     setOservacion(data.Resultado[0][0].Obs_Invitado)
                    //     setOpenModalUnicoInvitado(true)
                    // }

                    if (data.Resultado.length !== 0) {
                        let dataUsuario = data.Resultado[0];
                        setDataTable(dataUsuario);
                    } else {
                        setDataTable([]);
                        setOpenModalSinData(true)
                    }

                    if (data.Resultado[0][0].Flag_Asitio === "1" && data.Resultado.length === 1) 
                      {
                        setOpenModalCodUsado(true);
                      }
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const registrarAsistencia = () => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_G",
            },
            Parametros: [
                codigoUsuarioSeleccionado,
                codigo,
                nombreAsistente,
                pax ? pax.toString() : "1",
                numeroAsistente,
                email,
                "1",
                horaAsistido ? horaAsistido : obtenerFechaHoraActual(),
                observacion,
                "REYBER",
            ],
        };
        console.log(jsonData)
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    setOpenModalConfirmar(false)
                    limpiarCampos()
                    setCodigoUsuarioSeleccionado("")
                    buscarData(true)
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    };

    const fugitivo = (item) => {
        let jsonData = {
            Token: "484848",
            Operacion: "1",
            Conexion: {
                BaseDeDatos: "pale",
                Procedimiento: "USP_EVE_INVITADOS_G"
            },
            Parametros: [
                item.Cod_Invitado,
                item.Cod_Evento,
                item.Nom_Invitado,
                item.Pax.toString(),
                item.Celular,
                item.Email,
                "0",
                obtenerFechaHora(item.Asistio),
                item.Obs_Invitado,
                "REYBER"
            ],
        };
        console.log("==================")
        console.log(jsonData)
        console.log("==================")
        try {
            fetch("https://apis.paleconsultores.com/v2/Pale/", {
                method: "POST",
                body: JSON.stringify(jsonData),
                headers: { "Content-type": "application/json; charset=UTF-8" },
            })
                .then((res) => res.json())
                .then((data) => {
                    buscarData()
                    limpiarCampos()
                });
        } catch (error) {
            alert("Error con el servicio, por favor actualice la página");
        }
    }

    function obtenerFechaHoraActual() {
        const ahora = new Date();

        const anio = ahora.getFullYear();
        const mes = (ahora.getMonth() + 1).toString().padStart(2, "0");
        const día = ahora.getDate().toString().padStart(2, "0");
        const horas = ahora.getHours().toString().padStart(2, "0");
        const minutos = ahora.getMinutes().toString().padStart(2, "0");
        const segundos = ahora.getSeconds().toString().padStart(2, "0");

        return `${día}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
    }

    function obtenerFechaHora(x) {
        const ahora = new Date(x);

        const anio = ahora.getFullYear();
        const mes = (ahora.getMonth() + 1).toString().padStart(2, "0");
        const día = ahora.getDate().toString().padStart(2, "0");
        const horas = ahora.getHours().toString().padStart(2, "0");
        const minutos = ahora.getMinutes().toString().padStart(2, "0");
        const segundos = ahora.getSeconds().toString().padStart(2, "0");

        return `${día}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
    }

    const ingresarData = (item) => {
        setHoraAsistido(obtenerFechaHoraActual());
        setNombreAsistente(item.Nom_Invitado);
        setNumeroAsistente(item.Celular);
        setEmail(item.Email);
        setPax(item.Pax);
        setOservacion(item.Obs_Invitado);
    };

    function fechaPrueba(fecha) {
        const dateString = fecha;
        const indexOfT = dateString.indexOf('T');
        const timeWithoutDate = dateString.substring(11, 16);
        const dateWithoutTime = dateString.substring(0, indexOfT);
        return dateWithoutTime + ' ' + timeWithoutDate
    }


    // const ref = useRef();
    // const openTooltip = () => ref.current.open();
    // const closeTooltip = () => ref.current.close();

    // useEffect(() => {
    //     if (buscar.length == 12 || buscar.length == 36) {
    //         limpiarCampos();
    //         buscarPorCodigo();
    //     }
    // }, [buscar]);

    useEffect(() => {
        TraerEvento()
        buscarData();
        console.log("*******");
    }, []);

    console.log("-----------");


    return (
        <div>
            <nav className="header__navbar_boda">
                <nav className="navContainer2">
                    <div className="cont2logo">
                        <img className=".header__navbar_boda-image" src={logo1} alt="logoPale" />
                        <h1> {nombre}</h1>
                    </div>
                    <div>
                        <img className=".header__navbar_boda-image" src={logo2} alt="logoPale" />
                    </div>
                </nav>
            </nav>

            <div className='FormBusquedaEvento'>

                <div className='DivEvento'>
                    <div className='BuscadorEvento'>
                        <input
                            className='inputPrincipal'
                            type="text"
                            value={buscar}
                            onChange={(e) => {
                                setBuscar(e.target.value);
                            }}
                            onBlur={()=>{buscarData()}}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    buscarData();
                                }
                            }}
                            placeholder="Buscar invitados..." />
                    </div>

                    <BarcodeScanner
                        onScanSuccess={handleScanSuccess}
                        onScanError={handleScanError}
                    />
                </div>

                <div className='Body'>
                    <label className='titleRuc'>Lista de invitados </label>
                    <div className='EventosLista'>
                        {dataTable.map((item, index) => (
                            <div className="nuevatabla" key={index}>
                                <div className={item.Flag_Asitio === false ? "nuevatabla_D1" : "nuevatabla_D1Deshabil"}>
                                    <div className="nuevaTabla_Nombre">
                                        <div className="nuevaTabla_Nombre_content">
                                            <label>{item.Nom_Invitado}</label>
                                            <span>{item.Cod_Invitado}</span>
                                        </div>
                                        <div>
                                            <Popup
                                                // ref={ref}
                                                trigger={
                                                    <button
                                                        className="btnRevertir"
                                                        disabled={!item.Flag_Asitio}
                                                    >
                                                        {item.Flag_Asitio === false ? (
                                                            <IoCloseCircleOutline
                                                                size={23}
                                                                color="#35853c"
                                                            />
                                                        ) : (
                                                            <IoCheckmarkCircleOutline
                                                                size={23}
                                                                color="#79866a"
                                                            />
                                                        )}

                                                    </button>
                                                }
                                                position="left center"
                                            >
                                                <button
                                                    className="btnRevertir"
                                                    onClick={() => {
                                                        fugitivo(item);
                                                        // closeTooltip();
                                                    }}
                                                >
                                                    Unregister
                                                </button>
                                            </Popup>
                                        </div>
                                    </div>
                                    <div className="nuevaTabla_Datos">
                                        <div className="nuevatabla_content">
                                            <div className="nuevaTabla_Datos_in">
                                                <div className="nuevatabla_label">Celular:  </div><div className="nuevatabla_p">{item.Celular}</div>
                                            </div>
                                            <div className="nuevaTabla_Datos_in">
                                                <div className="nuevatabla_label">Correo:  </div><div className="nuevatabla_p">{item.Email}</div>
                                            </div>
                                        </div>
                                        <div className="nuevatabla_content">
                                            <div className="nuevaTabla_Datos_in">
                                                <div className="nuevatabla_label">Pases:</div><div className="nuevatabla_p">{item.Pax}</div>
                                            </div>
                                            <div className="nuevaTabla_Datos_in">
                                                <div className="nuevatabla_label">Hora:  </div><div className="nuevatabla_p">{fechaPrueba(item.Asistio)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nuevatabla_D2">
                                    <button
                                        className={item.Flag_Asitio === false ? "buttonHabil" : "buttonDesabil"}
                                        onClick={() => {
                                            ingresarData(item);
                                            setCodigoUsuarioSeleccionado(item.Cod_Invitado);
                                            // setOpenModalConfirmar(true);
                                            setOpenModalRegistro(true)
                                        }}
                                        disabled={item.Flag_Asitio}
                                    >
                                        {item.Flag_Asitio === false ? "Registrar" : "Registrado"}
                                    </button>
                                </div>
                            </div>
                        ))}
                        {/* ======================== MODALS =========================== */}
                        <div>
                            <Modal
                                isOpen={openModalRegistro}
                                onRequestClose={() => setOpenModalRegistro(false)}
                                ariaHideApp={false}
                                className="modalRegistro"
                            >
                                <div className="CabeceraModal">
                                    <h2>REGISTRAR INVITADO</h2>
                                    <div>
                                         <p>{codigoUsuarioSeleccionado}</p>
                                    </div>
                                </div>

                                <div className="bodyModal">

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPersonSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>

                                        <input
                                            type="text"
                                            value={nombreAsistente}
                                            onChange={(e) => {
                                                setNombreAsistente(e.target.value);
                                            }}
                                            placeholder="Nombre"
                                            disabled={ nombreAsistente === "" ? false : true}
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={numeroAsistente}
                                            onChange={(e) => {
                                                setNumeroAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoTicketSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={pax}
                                            onChange={(e) => {
                                                setPax(e.target.value);
                                            }}
                                            placeholder="Pases"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="PieModal">
                                    <button
                                        className="btnModal"
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            registrarAsistencia();
                                            // setOpenModalConfirmar(true);
                                            setCodigoUsuarioSeleccionado(buscar);
                                        }}
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalConfirmar}
                                onRequestClose={() => setOpenModalConfirmar(false)}
                                ariaHideApp={false}
                                className="modalConfirmar"
                            >
                                <div className="CabeceraModal">
                                    <h2>Confirmar Asistencia</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            setOpenModalConfirmar(false);
                                            setCodigoUsuarioSeleccionado("");
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>

                                <div className="avisoModal">
                                    <div className="avisoModalIn">
                                        <label>¿ Desea confirmar la asistencia de {nombreAsistente}? </label>
                                    </div>
                                </div>

                                <div className="PieModal">
                                    {/* <button
                                        className="btnModalCancel"
                                        onClick={() => {
                                            setOpenModalRegistro(false);
                                            setOpenModalConfirmar(false);
                                            setCodigoUsuarioSeleccionado("");
                                            setBuscar("")
                                        }}
                                    >
                                        Cancelar
                                    </button> */}

                                    <button className="btnModal"
                                        onClick={() => {
                                            registrarAsistencia("1")
                                            setOpenModalConfirmar(false);
                                            
                                        }}
                                    >Confirmar</button>
                                </div>

                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalSinData}
                                onRequestClose={() => setOpenModalSinData(false)}
                                ariaHideApp={false}
                                className="modalSinData"
                            >

                                <div className="CabeceraModalError">
                                    <h2>Datos incorrectos</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalSinData(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>
                                <div className="avisoModal">
                                    <label>Por favor, ingrese código o nombre correctos</label>
                                </div>
                                <div className="PieModal">
                                    <button
                                        className="btnModalError"
                                        onClick={() => {
                                            setOpenModalSinData(false);
                                            setBuscar("");
                                            buscarData(true)
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalCodUsado}
                                onRequestClose={() => setOpenModalCodUsado(false)}
                                ariaHideApp={false}
                                className="modalCodUsado"
                            >
                                <div className="CabeceraModalAlerta">
                                    <h2>Codigo {buscar}</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalCodUsado(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>
                                <div className="avisoModal">
                                    <label>Este código ya fue registrado!!</label>
                                </div>
                                <div className="PieModal">
                                    <button
                                        className="btnModalAlerta"
                                        onClick={() => {
                                            setOpenModalCodUsado(false);
                                            setBuscar("");
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Modal
                                isOpen={openModalUnicoInvitado}
                                onRequestClose={() => setOpenModalUnicoInvitado(false)}
                                ariaHideApp={false}
                                className="modalAsistenciaUnico"
                            >
                                <div className="CabeceraModal">
                                    <h2>Confirmar Asistencia</h2>
                                    <button
                                        onClick={() => {
                                            setOpenModalUnicoInvitado(false);
                                            setBuscar("")
                                        }}
                                    >X</button>
                                </div>

                                <div className="bodyModal">
                                    <div className="avisoModalIn">
                                        <label>¿ Desea confirmar la asistencia de {nombreAsistente}? </label>
                                    </div>

                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoPhonePortrait
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={numeroAsistente}
                                            onChange={(e) => {
                                                setNumeroAsistente(e.target.value);
                                            }}
                                            placeholder="Celular"
                                        />
                                    </div>
                                    <div className="bodyModalForm">
                                        <div className="iconoModal">
                                            <IoMailSharp
                                                size={30}
                                                color="white"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            placeholder="Correo"
                                        />
                                    </div>
                                </div>

                                <div className="PieModal">
                                    <button className="btnModal2"
                                        onClick={() => {
                                            registrarAsistencia()
                                            setOpenModalUnicoInvitado(false);
                                            setBuscar("");
                                        }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </Modal>
                        </div>
                        {/* ============================================================*/}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuscarEvento;
